import {
  API_BASE_URL,
  API_ENDPOINTS,
  API_PUBLIC_AUTH_TOKEN,
} from "../constants/api";
import { RCODES } from "../constants/rcodes";
import Http from "../services/http";

export const useSiteSettingsHook = () => {
  const loadSystemProperties = async () => {
    const url = `${API_BASE_URL}${API_ENDPOINTS.SYSTEM_PROPERTIES}`;
    const extraHeaders = {
      Authorization: API_PUBLIC_AUTH_TOKEN,
    };
    const res = await Http.get(url, extraHeaders);
    try {
      if (res.rcode === RCODES.SUCCESS) {
        const systemProperties = res.system_properties.reduce(
          (acc: any, curr: any) => {
            acc[curr.property_key] = curr.property_value;
            return acc;
          },
          {}
        );
        return systemProperties;
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const res = {
  //   rcode: 0,
  //   rmsg: "Success",
  //   system_properties: [
  //     {
  //       version: 14,
  //       property_key: "SA_AGE",
  //       property_value: "DETECTED",
  //     },
  //     {
  //       version: 14,
  //       property_key: "SA_COUNTRY",
  //       property_value: "DETECTED",
  //     },
  //     {
  //       version: 19,
  //       property_key: "SITE_LANGUAGE",
  //       property_value: "fr",
  //     },
  //     {
  //       version: 43,
  //       property_key: "SITE_COUNTRY",
  //       property_value: "FR",
  //     },
  //     {
  //       version: 32,
  //       property_key: "GEO_IP",
  //       property_value: "PH",
  //     },
  //   ],
  // };

  // const systemProperties = res.system_properties.reduce(
  //   (acc: any, curr: any) => {
  //     acc[curr.property_key] = curr.property_value;
  //     return acc;
  //   },
  //   {}
  // );

  // };

  return {
    loadSystemProperties,
  };
};
